import naja from 'naja';
import netteForms from 'nette-forms';

window.Nette = netteForms;

import 'jquery';
import 'jquery-ui-sortable'

import 'bootstrap';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';

import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import 'bootstrap-datepicker'

import 'bootstrap-select/dist/css/bootstrap-select.css'
import 'bootstrap-select'

naja.formsHandler.netteForms = netteForms;
naja.initialize();
// netteForms.initOnLoad();